import Main from "./layout/main/main";
import About from "./layout/about/about";
import Projects from "./layout/projects/projects";
import Footer from "./layout/footer/footer";

function App() {
  return (
    <>
    
    <Main/>
    <About/>
    <Projects/>
    <Footer/>
    </>
  );
}

export default App;
