export default function Footer() {
  return (
    <>
      <div className="container">
        <footer className="py-3 my-4">
          
          <p className="text-center text-muted">Made with <i class='heart bx bxs-heart' ></i> but also a little hatred for React</p>
        </footer>
      </div>
    </>
  );
}
