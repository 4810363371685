export default function Projects() {
  return (
    <>
      <div class="container container_projects  py-5">
        <h1 className="projects_title">projects</h1>
        <div className="album py-5">
          <div className="container">
            <div className="row">
            <div className="col-md-4">
                <div className="card mb-4 box-shadow">
                  <div className="card-body">
                    <p className="card-text">
                      Authentication with JWT and Spring Security. It was made as a study project to better understand dealing with Json Web Tokens. 
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <a
                        className="btn btn-sm btn-outline-secondary"
                        href="https://github.com/whathebea/jwt-security-spring-project"
                        target="_blank"
                      >
                        github
                      </a>
                      <div className="">
                        <span className="tech">Java</span>{" "}
                        <span className="tech">Spring</span>{" "}
                        <span className="tech">Spring Security</span>{" "}
                        <span className="tech">JWT</span>{" "}

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card mb-4 box-shadow">
                  <div className="card-body">
                    <p className="card-text">
                      Employee Management System made as a project for
                      University. It has: login, signup, listing employees,
                      adding employees, deleting employees and updating
                      employees{" "}
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <a
                        className="btn btn-sm btn-outline-secondary"
                        href="https://github.com/whathebea/how-iii"
                        target="_blank"
                      >
                        github
                      </a>

                      <div className="">
                        <span className="tech">Java</span>{" "}
                        <span className="tech">Spring</span>{" "}
                        <span className="tech">Thymeleaft</span>{" "}
                        <span className="tech">MySQL</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-4 box-shadow">
                  <div className="card-body">
                    <p className="card-text">
                      A task management service. You can create your tasks and
                      add progress to them as well.
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <a
                        className="btn btn-sm btn-outline-secondary"
                        href="https://github.com/whathebea/task-management-microservice"
                        target="_blank"
                      >
                        github
                      </a>
                      <div className="">
                        <span className="tech">Java</span>{" "}
                        <span className="tech">Spring</span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-4 box-shadow">
                  <div className="card-body">
                    <p className="card-text">
                      Arduino project of a silent fire alarm. The github
                      repository include both the circuit diagram and the code.
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <a
                        className="btn btn-sm btn-outline-secondary"
                        href="https://github.com/whathebea/how-viii"
                        target="_blank"
                      >
                        github
                      </a>
                      <div className="">
                        <span className="tech">Arduino</span>{" "}
                        <span className="tech">C++</span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-4 box-shadow">
                  <div className="card-body">
                    <p className="card-text">
                      A blog made with React, NextJS, Tailwind, GraphQL and
                      GraphCMS. GraphCMS acts as a content manager or a
                      dashboard for your blog posts and comments.
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <a
                        className="btn btn-sm btn-outline-secondary"
                        href="https://github.com/whathebea/personal-blog"
                        target="_blank"
                      >
                        github
                      </a>
                      <div className="">
                        <span className="tech">React</span>{" "}
                        <span className="tech">GraphQL</span>{" "}
                        <span className="tech">Tailwind</span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
