import illustration from "../../static/illustration.svg";

export default function Main() {
  return (
    <>
      <div className="container container1 ">
        <div className="row flex-lg-row align-items-center justify-content-center g-5 py-5">
          <div className="col-8 col-sm-8 col-lg-6">
            <img src={illustration} className=" img-fluid" alt="" />
          </div>
          <div className="col-lg-6">
            <h1 className=" fw-bold lh-1 mb-3">beatriz gracia</h1>
            <p className="main">
              I am a Software Developer based in Brazil! Currently an intern @
              IBM and working with Cloud Engineering.
            </p>
            <div className="d-flex justify-content-around">
              <a
                href="https://www.linkedin.com/in/bea-gracia/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bx bxl-linkedin"></i>
              </a>
              <a
                href="https://github.com/whathebea"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bx bxl-github"></i>
              </a>
              <a
                href="https://www.credly.com/users/beatriz-gracia-dos-santos/badges"
                target="_blank"
                rel="noreferrer"
              >
                <i class='bx bx-badge' ></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
