export default function About() {
  return (
    <>
      <div class="container container_about  py-5">
        <h1 className="about_title">about</h1>

        <div className="row">
          <div className="col-sm-6 col-md-5 col-lg-6">
            <h2 className="about_sub">education</h2>
            <p className="about_text">
              Associate Degree in Systems Development{" "}
              <span>10/21 - present</span>
            </p>
            <div className="italic">
              Universidade do Vale do Itajai (Univali)
            </div>
            <ul>
              <li>Developing web sites with HTML, CSS and Javascript</li>
              <li>
                Developing full-stack applications with Java and Thymeleaf
              </li>
              <li>Programming paradigms</li>
              <li>Algorithms and Data Structures</li>
              <li>User Experience Design</li>
              <li>Software Design</li>
            </ul>
            <p className="about_main_text">Projects created</p>
            <ul>
              <li>Binary-Decimal Calculator in C</li>
              <li>Personal Portfolio</li>
              <li>Employee Management System</li>
              <li>Small Arduino project with C++</li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0">
            <h2 className="about_sub">professional experience</h2>
            <p className="about_text">
              Cloud Engineering Intern <span>08/22 - present</span>
            </p>
            <div className="italic">IBM</div>
            <p className="about_main_text">
              I work in a squad which mainly develops solutions (REST APIs,
              Libraries, Sidecars) to be used in the public cloud. Those
              solutions usually are elaborated, tested and deployed with the
              following technologies:
            </p>
            <ul>
              <li>Java 11+ and Spring Framework</li>
              <li>Junit and Mockito</li>
              <li>MySQL, Postgres and Redis</li>
              <li>Docker</li>
              <li>Openshift </li>
            </ul>
            <p className="about_main_text">
              Besides the main technologies were also used:
            </p>
            <ul>
              <li>Apache Kafka</li>
              <li>Kubernetes</li>
            </ul>
          </div>
        </div>
        <h2 className="about_sub">skills</h2>
        <p className="about_main_text skills ">
          Java 11+ <span>(Core Java, Design Patterns)</span> | Databases{" "}
          <span>(MySQL, Posgres, Redis)</span> | Cloud{" "}
          <span>(Azure Fundamentals, GCP Fundamentals)</span> | Linux | GIT |
          Spring Framework <span>(Web, Security, JPA, Data, Actuator)</span> |
          Testing <span>(Junit, Mockito)</span> | Monitoring{" "}
          <span>(Prometheus, Grafana)</span> | Agile Methodologies{" "}
          <span>(SCRUM, Kanban)</span> | Basic Frontend{" "}
          <span>(HTML, CSS, Javascript)</span>
        </p>

        <h2 className="about_sub">certifications</h2>
        <ul className="certifications">
          <a
            href="https://www.credly.com/badges/d73dc7f2-ab62-4f54-bd55-40bb42279bb4"
            target="_blank"
            rel="noreferrer"
          >
            <li>
              Application Services Innovation - Java Foundations{" "}
              <span>(IBM)</span> <i className="bx bx-link-external"></i>
            </li>
          </a>
          <a
            href="https://www.credly.com/badges/a82d48cf-c15c-4896-ba6b-b5ce886110d8/"
            target="_blank"
            rel="noreferrer"
          >
            <li>
              Azure Fundamentals <span>(Microsoft)</span>{" "}
              <i className="bx bx-link-external"></i>
            </li>
          </a>
          <a
            href="https://www.cloudskillsboost.google/public_profiles/98adae40-e3b3-46bf-81ed-836152c24296/badges/2830387?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share"
            target="_blank"
            rel="noreferrer"
          >
            <li>
              Computing Foundations: Cloud Computing Fundamentals
              <span>(Google)</span> <i className="bx bx-link-external"></i>
            </li>
          </a>
        </ul>

        <h2 className="about_sub">courses</h2>
        <div className="row">
          <div className="col-sm-6 col-md-5 col-lg-6">
            <ul className="courses">
              <li>
                {" "}
                Microservices with Istio and IBM Cloud Kubernetes Service
                <span>Cognitive Class</span>
              </li>
              <li>
                Spring Framework in Easy Steps
                <span>Udemy</span>
              </li>
              <li>
                Design Patterns in Java
                <span>Udemy</span>
              </li>
              <li>
                Introduction to Containers, Kubernetes, and OpenShift V2
                <span>Cognitive Class</span>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-5 col-lg-6">
            <ul className="courses">
              <li>
                {" "}
                Docker Essentials: A Developer Introduction
                <span>Cognitive Class</span>
              </li>
              <li>
                Jenkins: Beginner to Pro
                <span>Udemy</span>
              </li>
              <li>
                The DevOps Essentials - The Handbook
                <span>Udemy</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
